var jquery = require("jquery");
window.$ = window.jQuery = jquery;
import Inputmask from "inputmask";
import Swiper, {Navigation,Autoplay,Pagination} from 'swiper';
import 'swiper/swiper-bundle.css';
Swiper.use([Navigation, Autoplay, Pagination]);

new Swiper('.swiper-container', {
  direction: 'horizontal',
  slidesPerView: 1,
  loop: true,
  autoplay: {
    delay: 12000,
    disableOnInteraction: false
  },
  navigation: {
    nextEl: '.swiper-container .swiper-button-next',
    prevEl: '.swiper-container .swiper-button-prev',
  },

  pagination: {
    el: '.swiper-container .swiper-pagination',
    clickable: true,
    type: 'bullets',
  },

  scrollbar: {
    el: '.swiper-container .swiper-scrollbar',
    dragSize: '20px',
  },
})

new Swiper('.swiper-container-blog', {
  direction: 'horizontal',
  slidesPerView: 1,
  loop: true,
  autoplay: {
    delay: 12000,
    disableOnInteraction: false
  },

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    type: 'bullets',
  },
  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
    dragSize: '20px',
  },
})

function submitForm(form) {

  var error = false; // предварительно ошибок нет

  form.find('input').each(function () { // пробежим по каждому полю в форме
    if ($(this).val() == '') { // если находим пустое
      alert('Заполните поле "' + $(this).attr('placeholder') + '"!'); // говорим заполняй!
      error = true; // ошибка
    }
  });

  if (!error) { // если ошибки нет
    var data = form.serialize(); // подготавливаем данные
    $.ajax({ // инициализируем ajax запрос
      type: 'POST', // отправляем в POST формате, можно §§§ET
      url: 'sendMail.php', // путь до обработчика, у нас он лежит в той же папке
      dataType: 'json', // ответ ждем в json формате
      data: data, // данные для отправки
      beforeSend: function (data) { // событие до отправки
        form.find('input[type="submit"]').attr('disabled', 'disabled'); // например, отключим кнопку, чтобы не жали по 100 раз
      },
      success: function (data) { // событие после удачного обращения к серверу и получения ответа
        if (data['error']) { // если обработчик вернул ошибку
          alert(data['error']); // покажем её текст
        } else { // если все прошло ок
          $('.modal').hide();

          $('#result-form').show();

          //setTimeout(function () {
           // $('#result-form').hide();
          //}, 2000)
        }
      },
      error: function (xhr, ajaxOptions, thrownError) { // в случае неудачного завершения запроса к серверу
        alert(xhr.status); // покажем ответ сервера
        alert(thrownError); // и текст ошибки
      },
      complete: function (data) { // событие после любого исхода
        form.find('input[type="submit"]').prop('disabled', false); // в любом случае включим кнопку обратно
      }

    });
  }
  return false; // вырубаем стандартную отправку формы
}

//const im = new Inputmask("+ 9 9 9 9 9 9 9 9 9 9 9 9", {
//  placeholder: '+ _ _ _ _ _ _ _ _ _ _ _ _',
//});
//im.mask('form #tel');

$("#modal-form form").on('submit', function (e) { // перехватываем все при событии отправки
  e.preventDefault();
  submitForm($(e.currentTarget))
});

$('.cta, .cta-mobile').on('click', function () {
  $('#modal-form').show();
});

$('#modal-form .modal-close').on('click', function () {
  $('#modal-form').hide();
});

$('#result-form .modal-close').on('click', function () {
  $('#result-form').hide();
});


function burgerMenu() {
	let menu = $('.burger_menu');
	let button = menu.find('.burger_menu_button');

	button.on('click', (e) => {
		e.preventDefault();
		menu.toggleClass('burger_menu_active');
		$('html, body').toggleClass('overflowHidden');
	});
}

burgerMenu()


